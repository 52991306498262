import classNames from 'classnames'
import type { ReactNode } from 'react'

export type DescriptionProps = {
  title: ReactNode
  content: ReactNode
  className?: string
}

export function Description({ title, content, className }: DescriptionProps) {
  return (
    <div className={classNames('flex-grow', className)}>
      <dt className='text-xs font-medium text-gray-500 dark:text-gray-400'>{title}</dt>
      <dd className='mt-1 text-xs text-gray-900 dark:text-gray-300 sm:mt-0 sm:col-span-2'>
        {content}
      </dd>
    </div>
  )
}
