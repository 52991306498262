import { cn } from '@/ui/utils'
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import type React from 'react'

export interface TableColumn<T extends { id: string | number }> {
  name: string
  value: (row: T, index: number) => JSX.Element | string | null
  header?: (data: T[] | undefined) => JSX.Element | string | null
  className?: string | ((data: T) => string)
}

export interface TableProps<T extends { id: string | number }> {
  cols: TableColumn<T>[]
  data: T[] | undefined
  striped?: boolean
  offsetTop?: number
  emptyState?: React.ReactNode
  freezeFirstCol?: boolean

  borderHidden?: boolean
}

export function Table<T extends { id: string | number }>(props: TableProps<T>) {
  const { striped = false } = props
  if (!props.data) {
    return <TableLoading />
  }

  if (!props.data.length) {
    return <TableEmpty />
  }

  return (
    <div className='flex flex-col'>
      {/* note: removed overflow-x-auto from tailwindui template */}
      <div className='-my-2 '>
        <div className='inline-block min-w-full py-2 align-middle'>
          {/* note: removed overflow-hidden from tailwindui template */}
          <div
            className={classNames(
              'shadow border-b border-gray-200 dark:border-gray-700 rounded-lg',
              {
                'dark:border': !striped
              }
            )}
          >
            <table className='min-w-full divide-y divide-gray-200 dark:divide-gray-700'>
              <thead className='bg-gray-100 dark:bg-gray-800'>
                <tr>
                  {props.cols.map((col) => (
                    <th
                      key={col.name}
                      scope='col'
                      className={classNames(
                        'px-3 sm:px-4 md:px-5 lg:px-6 py-3 text-left text-[11px] text-gray-500 dark:text-gray-400 uppercase tracking-wider first:rounded-tl-lg last:rounded-tr-lg',
                        typeof col.className === 'function'
                          ? col.className(props.data![0])
                          : col.className
                      )}
                    >
                      {col.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody
                className={classNames({
                  'divide-y divide-gray-200 dark:divide-gray-600': !striped || props.borderHidden
                })}
              >
                {props.data.map((data, rIndex) => (
                  <tr
                    key={data.id}
                    className={classNames('last:rounded-b-lg', {
                      'bg-white dark:bg-offBlack': !striped,
                      'bg-white dark:bg-gray-800': striped && rIndex % 2 === 0,
                      'bg-gray-50 dark:bg-gray-900': striped && rIndex % 2 !== 0
                    })}
                  >
                    {props.cols.map((col, cIndex) => (
                      <td
                        key={data.id + col.name}
                        className={classNames(
                          'px-3 sm:px-4 md:px-5 lg:px-6 py-4 whitespace-nowrap text-sm',
                          {
                            'font-medium text-gray-900 dark:text-gray-50': cIndex === 0,
                            'font-regular text-gray-600 dark:text-gray-300': cIndex !== 0,
                            'first:rounded-bl-lg last:rounded-br-lg':
                              rIndex === (props?.data?.length ?? 0) - 1
                          },
                          typeof col.className === 'function' ? col.className(data) : col.className
                        )}
                      >
                        {col.value(data, rIndex)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export interface TableGroupProps {
  title?: string
  sticky?: boolean
  children: JSX.Element | JSX.Element[]
}

export function TableGroup(props: TableGroupProps) {
  return (
    <div className='mt-2 mb-4'>
      {props.title ? (
        <div className='relative mb-2'>
          <div className='relative flex items-center justify-start space-x-1 flex-nowrap'>
            <span className='flex-shrink-0 pl-3 pr-3 text-sm font-bold text-gray-900 text-md dark:text-gray-200'>
              {props.title}
            </span>
          </div>
        </div>
      ) : null}
      {props.children}
    </div>
  )
}

export function TableV2<T extends { id: string | number }>(props: TableProps<T>) {
  if (!props.cols || props.cols?.length === 0) return null
  if (!props.data) {
    return (
      <div className='p-2'>
        <TableLoading />
      </div>
    )
  }

  if (!props.data.length) {
    return (
      <div className='p-2'>
        {props.emptyState ? <TableEmpty>{props.emptyState}</TableEmpty> : <TableEmpty />}
      </div>
    )
  }
  return (
    <div className='flex-1 px-4 sm:px-6 lg:px-8'>
      <div className='flex flex-col'>
        <div className='-mx-4 -my-2 sm:-mx-6 lg:-mx-8'>
          <div className='inline-block max-w-full min-w-full py-2 align-middle'>
            <div className='shadow-sm ring-1 ring-black ring-opacity-5'>
              <table
                className={cn('min-w-full', { 'divide-y divide-zinc-800': !props.borderHidden })}
              >
                <thead
                  className='sticky z-10 bg-zinc-900'
                  style={{ top: props.offsetTop ?? '61px' }}
                >
                  <tr>
                    {props.cols.map((col, idx) => (
                      <th
                        key={col.name}
                        scope='col'
                        className={classNames(
                          ' bg-zinc-900 first-of-type:pl-4 first-of-type:sm:pl-4 first-of-type:pr-2',
                          'px-1 py-2 !text-[11px] !font-normal text-left text-zinc-100',
                          'last-of-type:pl-3 last-of-type:pr-4 last-of-type:sm:pr-4 last-of-type:lg:pr-4 last-of-type:text-right',
                          props.freezeFirstCol &&
                            idx === 0 &&
                            '!sticky left-0 top-0 h-fit z-10 bg-zinc-900',
                          typeof col.className === 'function'
                            ? col.className(props.data![0])
                            : col.className
                        )}
                      >
                        {typeof col.header === 'function' ? col.header(props.data) : col.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                {props.data && (
                  <tbody
                    className={cn('bg-black', {
                      'divide-y divide-zinc-700': !props.borderHidden
                    })}
                  >
                    {props.data.map((row, i) => (
                      <tr key={row.id}>
                        {props.cols.map((col, idx) => (
                          <td
                            key={`${row.id}:${col.name}`}
                            className={classNames(
                              'first-of-type:pl-4 first-of-type:sm:pl-4 first-of-type:pr-2 first-of-type:text-zinc-100 first-of-type:whitespace-nowrap font-medium',
                              'px-1 py-2 text-xs text-zinc-400',
                              'relative last-of-type:pl-2 last-of-type:pr-4 last-of-type:text-right last-of-type:whitespace-nowrap last-of-type:sm:pr-4 last-of-type:lg:pr-4',
                              props.freezeFirstCol &&
                                idx === 0 &&
                                '!sticky left-0 h-fit z-[9] bg-black',
                              typeof col.className === 'function'
                                ? col.className(row)
                                : col.className
                            )}
                          >
                            {col.value(row, i)}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export function TableEmpty(props: { text?: string; children?: React.ReactNode }) {
  return (
    <div className='relative block w-full p-12 text-center border border-gray-300 border-dashed rounded-lg dark:border-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
      <span className='block mt-2 text-sm text-gray-500 font-regular dark:text-gray-600'>
        {props.children ?? props.text ?? 'No Results'}
      </span>
    </div>
  )
}

export function TableLoading() {
  return (
    <div className='relative block w-full p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'>
      <ArrowPathIcon
        className='inline-block w-4 h-4 mt-2 text-gray-500 animate-spin dark:text-gray-600'
        aria-hidden='true'
      />
    </div>
  )
}
