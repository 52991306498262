'use client'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React, { Fragment, type MutableRefObject, type ReactNode, useState } from 'react'

export interface ModalProps {
  title?: ReactNode
  open: boolean
  setOpen: (val: boolean) => void
  children: ReactNode
  footer?: ReactNode
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  initialFocus?: MutableRefObject<HTMLButtonElement | HTMLInputElement | null>
}

export function Modal(props: ModalProps) {
  return (
    <Transition.Root show={props.open} appear as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-50 overflow-y-auto'
        initialFocus={props.initialFocus}
        onClose={props.setOpen}
      >
        <div className='justify-center min-h-screen px-4 pt-4 pb-20 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            enterTo='opacity-100 translate-y-0 sm:scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 sm:scale-100'
            leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          >
            <div
              className={classNames(
                'inline-block align-bottom bg-white dark:bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full',
                {
                  'max-w-lg': props.size === 'xs',
                  'max-w-screen-sm': props.size === 'sm',
                  'max-w-screen-md': props.size === 'md' || !props.size, // default
                  'max-w-screen-lg': props.size === 'lg'
                }
              )}
            >
              <div className='absolute top-0 right-0 pt-4 pr-4'>
                <button
                  type='button'
                  className='text-gray-400 bg-transparent rounded-md dark:text-gray-300 dark:hover:text-gray-50 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:ring-offset-black focus:ring-green-500'
                  onClick={() => props.setOpen(false)}
                >
                  <span className='sr-only'>Close</span>
                  <XMarkIcon className='w-5 h-5' aria-hidden='true' />
                </button>
              </div>
              {props.title && (
                <div className='px-4 pt-5 sm:px-6 sm:pt-5'>
                  <div className='text-left'>
                    <Dialog.Title
                      as='h3'
                      className='text-base font-medium leading-6 text-gray-900 dark:text-gray-200'
                    >
                      {props.title}
                    </Dialog.Title>
                  </div>
                </div>
              )}
              {props.children}
              {props.footer && <ModalFooter>{props.footer}</ModalFooter>}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

type ModalContentProps = {
  children: ReactNode
}

export function ModalContent({ children }: ModalContentProps) {
  return <div className='px-4 py-6 sm:px-6'>{children}</div>
}

Modal.Content = ModalContent

type ModalFooterProps = {
  children: ReactNode
}

function getResolvedChildren(children: ReactNode) {
  if (!React.isValidElement(children)) return children
  return children.type === React.Fragment ? children.props.children : children
}

export function ModalFooter({ children }: ModalFooterProps) {
  return (
    <div className='flex justify-between w-full px-4 py-3 space-x-3 bg-zinc-900 sm:px-6 sm:justify-end'>
      {React.Children.map(getResolvedChildren(children), (child) => {
        return React.cloneElement(child, { className: `${child.props.className} w-full sm:w-auto` })
      })}
    </div>
  )
}

export function useModal(props: Omit<ModalProps, 'open' | 'setOpen'>) {
  const [open, setOpen] = useState(false)
  return {
    open,
    setOpen,
    Modal: <Modal {...props} open={open} setOpen={setOpen} />
  }
}

Modal.Footer = ModalFooter
