export interface CardProps {
  title?: JSX.Element | string | null
  subtitle?: JSX.Element | string | null
  children?: JSX.Element | JSX.Element[] | null
  footer?: JSX.Element[] | JSX.Element | null
}

export function Card(props: CardProps) {
  return (
    <div className='overflow-hidden border rounded-md shadow sm:rounded-md dark:border-zinc-800'>
      <div className='px-4 py-4 space-y-6 bg-white dark:bg-black sm:p-6 rounded-t-md'>
        <div>
          {typeof props.title === 'string' ? (
            <h3 className='text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-200'>
              {props.title}
            </h3>
          ) : (
            props.title
          )}
          {typeof props.subtitle === 'string' ? (
            <p className='mt-1 text-sm text-zinc-500 dark:text-zinc-400'>{props.subtitle}</p>
          ) : (
            props.subtitle
          )}
        </div>
        {props.children}
      </div>
      {props.footer && (
        <div className='px-4 py-3 text-right border-t bg-zinc-100 dark:bg-zinc-900 sm:px-6 border-zinc-300 dark:border-zinc-700 rounded-b-md'>
          {props.footer}
        </div>
      )}
    </div>
  )
}
