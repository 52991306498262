import { type ClassValue, clsx } from 'clsx'
import { DateTime } from 'luxon'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatDate(date: Date, format = 'MM/dd/yy') {
  return DateTime.fromJSDate(date).toFormat(format)
}
