import { ExclamationCircleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import React, { type ChangeEventHandler, type FocusEvent, type ReactNode, useId } from 'react'

import type { MaybeTextInputVal, SVGElement } from 'ui/types'

export type BaseInputProps = {
  name?: string
  value?: string | number | readonly string[] | undefined
  id?: string
  type?: string | undefined
  label?: ReactNode
  placeholder?: string
  autoComplete?: string
  required?: boolean
  caption?: ReactNode
  className?: string | string[]
  error?: string | undefined
  leadingIcon?: SVGElement
  trailingIcon?: SVGElement
  hint?: ((props: BaseInputProps) => ReactNode) | ReactNode
  labelHidden?: boolean
  meta?: Record<string, any>
  touched?: boolean | undefined
  input?: Record<string, any>
  disabled?: boolean
}

export type InputProps = BaseInputProps & {
  children?: ReactNode
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: (event?: FocusEvent<HTMLElement> | undefined) => void
  onFocus?: (event?: FocusEvent<HTMLElement> | undefined) => void
  roundedTop?: boolean
  roundedBottom?: boolean
}

export type TextAreaProps = BaseInputProps & {
  onChange?: ChangeEventHandler<HTMLTextAreaElement>
  onBlur?: (event?: FocusEvent<HTMLElement> | undefined) => void
  onFocus?: (event?: FocusEvent<HTMLElement> | undefined) => void
  rows?: number
}

export type InputGroupProps = {
  className?: string
  children: JSX.Element | JSX.Element[]
}

export function parseNumber(defaultVal: MaybeTextInputVal = '') {
  return (val: string): MaybeTextInputVal => {
    return val === '' ? defaultVal : val?.replace(/[^0-9.]/g, '')
  }
}

export function InputGroup(props: InputGroupProps) {
  return (
    <div className={classNames('rounded-md shadow-sm -space-y-px', props.className)}>
      {React.Children.map(props.children, (child, index) =>
        React.cloneElement(child, {
          ...child?.props,
          className: classNames({
            'mt-0': index > 0,
            'rounded-t-none': index > 0,
            'rounded-b-none': index < React.Children.count(props.children) - 1
          })
        })
      )}
    </div>
  )
}

export function EmailInput(props: InputProps) {
  const { label = 'Email Address', placeholder = 'Email Address' } = props
  return (
    <Input
      {...props.input}
      {...props}
      label={label}
      placeholder={placeholder}
      autoComplete='email'
      error={props?.meta?.touched ? props?.meta?.error : undefined}
      type='email'
    />
  )
}

export function PasswordInput(props: InputProps) {
  const { label = 'Password', placeholder = 'Password', autoComplete = 'current-password' } = props
  return (
    <Input
      {...props.input}
      {...props}
      label={label}
      placeholder={placeholder}
      error={props?.meta?.touched ? props?.meta?.error : undefined}
      type='password'
      autoComplete={autoComplete}
    />
  )
}

export function NumberInput(props: InputProps) {
  return (
    <Input
      {...props.input}
      {...props}
      error={props?.meta?.touched ? props?.meta?.error : undefined}
      type='number'
    />
  )
}

export function TextInput(props: InputProps) {
  return (
    <Input
      {...props.input}
      {...props}
      error={props?.meta?.touched ? props?.meta?.error : undefined}
      type='text'
    />
  )
}

export function DateInput(props: InputProps) {
  return (
    <Input
      {...props.input}
      {...props}
      error={props?.meta?.touched ? props?.meta?.error : undefined}
      type='date'
    />
  )
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const id = useId()

  return (
    <div>
      {((props.label && !props.labelHidden) || props.error || props.hint) && (
        <div
          className={classNames('mb-1 flex items-end', {
            'justify-between': props.label && !props.labelHidden,
            'justify-end': !props.label || props.labelHidden
          })}
        >
          {props.label && (
            <label
              htmlFor={id}
              className={classNames('block text-xs font-medium', {
                'sr-only': props.labelHidden,
                'text-zinc-700 dark:text-zinc-300': !props.disabled,
                'text-zinc-500 dark:text-zinc-500': props.disabled
              })}
            >
              {props.label}
            </label>
          )}
          {props.error ? (
            <p className='text-xs text-red-600 dark:text-red-500'>{props.error}</p>
          ) : props.hint ? (
            <span className='block text-xs text-gray-500 dark:text-gray-400'>
              {typeof props.hint === 'function' ? props.hint(props) : props.hint}
            </span>
          ) : null}
        </div>
      )}
      <div className='relative flex rounded-md shadow-sm'>
        {props.children &&
          (props.label ? (
            <span
              className={classNames(
                'bg-gray-50 border border-r-0 border-zinc-300 rounded-l-md px-3 inline-flex items-center text-zinc-500 sm:text-xs dark:bg-zinc-800 dark:border-zinc-500 dark:text-gray-300 whitespace-nowrap',
                props.className
              )}
            >
              {props.children}
            </span>
          ) : (
            <label
              htmlFor={id}
              className={classNames(
                'bg-gray-50 border border-r-0 border-zinc-300 rounded-l-md px-3 inline-flex items-center text-zinc-500 sm:text-xs dark:bg-gray-700 dark:border-gray-500 dark:text-gray-300 whitespace-nowrap',
                props.className
              )}
            >
              {props.children}
            </label>
          ))}
        <div className='relative w-full min-w-0'>
          {props.leadingIcon && (
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <props.leadingIcon className='w-5 h-5 text-zinc-400' aria-hidden='true' />
            </div>
          )}
          <input
            id={id}
            ref={ref}
            name={props.name}
            value={props.value}
            type={props.type ?? 'text'}
            autoComplete={props.autoComplete}
            required={props.required}
            onChange={props.onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            disabled={props.disabled}
            className={classNames(
              'focus:ring-green-500 focus:border-green-500 py-1 px-2 flex-grow block w-full min-w-0 rounded-md text-base sm:text-xs dark:bg-black',
              {
                'border-zinc-200 dark:border-zinc-700': props.disabled,
                'border-zinc-300   dark:border-zinc-700 dark:text-zinc-300 placeholder-zinc-400 dark:placeholder-zinc-600 text-zinc-700':
                  !props.disabled,
                'rounded-l-none': !!props.children,
                'border-red-300 dark:border-red-300 text-red-700  dark:text-red-500 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500':
                  !props.disabled && !!props.error,
                'opacity-600 cursor-not-allowed border-': props.disabled
                // 'pl-2': !!props.leadingIcon,
              },
              props.className
            )}
            placeholder={props.placeholder}
          />
          {props.trailingIcon && !!props.error && (
            <div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
              <props.trailingIcon className='w-5 h-5 text-zinc-400' aria-hidden='true' />
            </div>
          )}
          {props.error && (
            <div className='absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none'>
              <ExclamationCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
            </div>
          )}
        </div>
      </div>
      {props.caption ? (
        <p className='mt-1.5 text-xs text-gray-500 dark:text-gray-400'>{props.caption}</p>
      ) : null}
    </div>
  )
})

Input.displayName = 'Input'

export function TextArea(props: TextAreaProps) {
  const id = useId()

  return (
    <div>
      <div
        className={classNames('mb-1 flex items-end', {
          'justify-between': props.label && !props.labelHidden,
          'justify-end': !props.label || props.labelHidden
        })}
      >
        {props.label && (
          <label
            htmlFor={id}
            className={classNames('block text-sm font-medium text-gray-700 dark:text-gray-300', {
              'sr-only': props.labelHidden
            })}
          >
            {props.label}
          </label>
        )}
        {props.error ? (
          <p className='text-xs text-red-600 dark:text-red-500'>{props.error}</p>
        ) : props.hint ? (
          <span className='block text-xs text-gray-500 dark:text-gray-400'>
            {typeof props.hint === 'function' ? props.hint(props) : props.hint}
          </span>
        ) : null}
      </div>
      <textarea
        id={id}
        rows={props.rows ?? 3}
        name={props.name}
        autoComplete={props.autoComplete}
        value={props.value}
        required={props.required}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        className={classNames(
          'shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border border-gray-300 rounded-md dark:bg-black dark:border-gray-500 dark:text-gray-300 disabled:border-opacity-40 disabled:border-gray-500 dark:disabled:border-gray-500',
          {
            'border-red-300 dark:border-red-300 text-red-700  dark:text-red-500  focus:outline-none focus:ring-red-500 focus:border-red-500':
              !!props.error
          },
          props.className
        )}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
      {props.caption ? (
        <p className='mt-1.5 text-xs text-gray-500 dark:text-gray-400'>{props.caption}</p>
      ) : null}
    </div>
  )
}

export function TextAreaField(props: TextAreaProps) {
  return (
    <TextArea
      {...props.input}
      {...props}
      error={props?.meta?.touched ? props?.meta?.error : undefined}
    />
  )
}
