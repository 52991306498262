import { ArrowPathIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import React, { type ForwardedRef, type ReactNode } from 'react'
import type { SVGElement } from 'ui/types'

export type ButtonProps = {
  type?: 'button' | 'submit'
  onClick?: () => void
  loading?: boolean
  disabled?: boolean
  children?: ReactNode
  className?: string
  iconLeft?: SVGElement
  iconRight?: SVGElement
  xs?: boolean
  sm?: boolean
  md?: boolean
  lg?: boolean
  square?: boolean
  icon?: SVGElement
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      onClick,
      loading = false,
      disabled = false,
      className,
      children,
      sm,
      md = true,
      lg,
      xs,
      ...props
    }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const _md = md && !(sm || lg || xs)
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled || loading}
        className={classNames(
          'inline-flex justify-center rounded-md border shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
          {
            'text-sm': !xs,
            'pointer-events-none opacity-40': disabled
          },
          props.square
            ? {
                'px-1 py-1 text-xs': xs,
                'px-1.5 py-1.5 text-xs': !xs
              }
            : {
                'px-1.5 py-0.5 text-xs': xs,
                'px-2 py-0.5': sm,
                'py-1 px-4': _md,
                'py-1.5 px-6': lg
              },
          className
        )}
        ref={ref}
      >
        {props.iconLeft && (
          <props.iconLeft className={classNames('-ml-1 mr-2 h-5 w-5')} aria-hidden='true' />
        )}
        {props.icon ? <props.icon className='w-5 h-5' /> : children}
        {loading && (
          <ArrowPathIcon className='w-4 h-4 ml-2 text-white animate-spin' aria-hidden='true' />
        )}
        {!loading && props.iconRight && (
          <props.iconRight className={classNames('ml-2 -mr-1 h-5 w-5')} aria-hidden='true' />
        )}
      </button>
    )
  }
)

Button.displayName = 'Button'

export const ButtonSecondary = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, ...props }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <Button
        {...props}
        className={classNames(
          'bg-white border-zinc-300 text-zinc-700 focus:ring-green-500 ',
          'dark:bg-black dark:border-zinc-700 dark:text-zinc-400 dark:hover:text-zinc-300 dark:hover:bg-black dark:hover:border-zinc-500',
          className
        )}
        ref={ref}
      >
        {children}
      </Button>
    )
  }
)

ButtonSecondary.displayName = 'ButtonSecondary'

export const ButtonPrimary = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, ...props }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <Button
        {...props}
        className={classNames(
          'text-white',
          'bg-green-600  border-transparent hover:bg-green-700 focus:ring-green-500',
          'dark:bg-green-600 dark:hover:bg-green-700',
          className
        )}
        ref={ref}
      >
        {children}
      </Button>
    )
  }
)

ButtonPrimary.displayName = 'ButtonPrimary'

export const ButtonTertiary = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, ...props }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <Button
        {...props}
        className={classNames(
          'bg-gray-600 border-transparent text-white hover:bg-gray-700  focus:ring-offset-gray-800 focus:ring-green-500',
          'dark:bg-gray-600 dark:hover:bg-gray-700',
          className
        )}
        ref={ref}
      >
        {children}
      </Button>
    )
  }
)

ButtonTertiary.displayName = 'ButtonTertiary'

export const ButtonDanger = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, ...props }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    return (
      <Button
        {...props}
        className={classNames(
          'bg-red-500 border-transparent  text-white hover:bg-red-700  focus:ring-red-500',
          'dark:bg-red-600 dark:hover:bg-red-700',
          className
        )}
        ref={ref}
      >
        {children}
      </Button>
    )
  }
)

ButtonDanger.displayName = 'ButtonDanger'
