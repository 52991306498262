'use client'
import { Menu, Transition } from '@headlessui/react'
import type React from 'react'
import { Fragment, MutableRefObject } from 'react'

import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import Link from 'next/link'

import classNames from 'classnames'
import type { ReactNode } from 'react'
import type { SVGElement } from 'ui/types'

export type MenuOption = {
  label: ((p: ItemContentProps) => ReactNode) | ReactNode
  href?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  icon?: SVGElement
}

export interface ItemContentProps {
  active: boolean
  option: MenuOption
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

function ItemContent(props: ItemContentProps) {
  const { active, option, onClick, ...rest } = props
  const className = classNames(
    'cursor-default text-left text-xs select-none relative py-2 pl-2 mx-1 pr-9 rounded flex items-center',
    active ? 'text-white bg-zinc-700' : 'text-gray-700 dark:text-gray-100'
  )

  const icon = option.icon ? (
    <option.icon className='w-4 h-4 mr-3 text-gray-400 dark:text-gray-300' aria-hidden='true' />
  ) : null

  const label = typeof option.label === 'function' ? option.label({ active, option }) : option.label

  return option.href ? (
    <Link href={option.href} {...rest} className={className}>
      {icon}
      {label}
    </Link>
  ) : (
    <button
      className={className}
      {...rest}
      onClick={(e) => {
        if (typeof onClick === 'function') {
          onClick(e)
        }
        if (typeof option.onClick === 'function') {
          option.onClick(e)
        }
      }}
    >
      {icon}
      {label}
    </button>
  )
}

export interface DropdownMenuProps {
  children?: ReactNode
  options: Array<MenuOption | MenuOption[]>
  onClick?: (e: any) => void
  disabled?: boolean
}

export function DropdownMenu({ options = [], disabled }: DropdownMenuProps) {
  disabled = disabled || options.length === 0
  return (
    <Menu as='div' className='relative inline-block text-left'>
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              disabled={disabled}
              className={classNames(
                'rounded hover:bg-zinc-800 py-0.5 px-0.5 flex items-center focus:outline-none bg-transparent',
                {
                  'bg-zinc-800': open,
                  'dark:text-gray-300 text-gray-500': !disabled,
                  'dark:text-gray-500 text-gray-300 hover:cursor-not-allowed': disabled
                }
              )}
            >
              <span className='sr-only'>Open options</span>
              <EllipsisVerticalIcon className='w-5 h-5' aria-hidden='true' />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter='transition ease-out duration-100'
            enterFrom='transform opacity-0 scale-95'
            enterTo='transform opacity-100 scale-100'
            leave='transition ease-in duration-75'
            leaveFrom='transform opacity-100 scale-100'
            leaveTo='transform opacity-0 scale-95'
          >
            <Menu.Items className='absolute right-0 z-10 flex flex-col w-full py-1 mt-1 overflow-auto text-base border rounded-md shadow-lg group bg-zinc-900 min-w-max border-zinc-700 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
              {options.map((option, index) =>
                Array.isArray(option) ? (
                  option.map((groupedOption, innerIndex) => (
                    <Menu.Item key={`group-${index}-option-${innerIndex}`}>
                      {({ active }) => <ItemContent active={active} option={groupedOption} />}
                    </Menu.Item>
                  ))
                ) : (
                  <Menu.Item key={index}>
                    {({ active }) => <ItemContent active={active} option={option} />}
                  </Menu.Item>
                )
              )}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}

export default DropdownMenu
