'use client'
import React, { useRef, useState } from 'react'
import { ButtonPrimary, ButtonSecondary, Modal, type ModalProps } from 'ui/atoms'

export interface ActionModalProps extends ModalProps {
  title: string
  action: string
  submit: (props?: { formValues?: Record<string, any> }) => Promise<any>
  open: boolean
  setOpen: (val: boolean) => void
  children: JSX.Element | JSX.Element[]
}

export function ActionModal(props: ActionModalProps) {
  const [loading, setLoading] = useState(false)

  const cancelButtonRef = useRef<HTMLButtonElement>(null)

  const performAction = async () => {
    try {
      setLoading(true)
      await props.submit()
      props.setOpen(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      size='sm'
      initialFocus={cancelButtonRef}
      {...props}
      footer={
        <>
          <ButtonSecondary onClick={() => props.setOpen(false)} ref={cancelButtonRef}>
            Cancel
          </ButtonSecondary>
          <ButtonPrimary onClick={performAction} loading={loading}>
            {props.action}
          </ButtonPrimary>
        </>
      }
    >
      <Modal.Content>{props.children}</Modal.Content>
    </Modal>
  )
}
