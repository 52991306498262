import classNames from 'classnames'
import React, { type ReactNode } from 'react'

export type BadgeType = 'success' | 'warning' | 'error' | 'info'

export interface BadgeProps {
  type?: BadgeType
  children: ReactNode
  red?: boolean
  yellow?: boolean
  green?: boolean
  blue?: boolean
  purple?: boolean
  orange?: boolean
  className?: string | undefined
  sm?: boolean
  lg?: boolean
}

export function Badge({
  type,
  green,
  red,
  yellow,
  blue,
  purple,
  orange,
  children,
  className,
  sm,
  lg
}: BadgeProps) {
  return (
    <span
      className={classNames(
        'inline-flex items-center leading-1 tracking-tight text-xs shadow',
        {
          'bg-red-100 text-red-800': red || type === 'error',
          'bg-yellow-100 text-yellow-800': yellow || type === 'warning',
          'bg-green-100 text-green-800': green || type === 'success',
          'bg-blue-100 text-blue-800': blue || type === 'info',
          'bg-purple-100 text-purple-800': purple,
          'bg-orange-100 text-orange-800': orange
        },
        {
          'px-1 rounded': sm,
          'font-semibold rounded-full': !sm,
          'px-2 leading-5': !lg && !sm,
          'px-3 leading-7': lg
        },
        className
      )}
    >
      {children}
    </span>
  )
}
