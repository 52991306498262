import classNames from 'classnames'

export type Stat = {
  label: string
  value: string | number
}

export interface StatsProps {
  items: Stat[]
  cols: 1 | 2 | 3 | 4 | 5 | 6
  lg?: boolean
  sm?: boolean
}

export function Stats({ items, cols, lg, sm }: StatsProps): JSX.Element {
  return (
    <div>
      <dl
        className={classNames('mt-5 grid gap-5', {
          'grid-cols-1': cols <= 1,
          'grid-cols-2': cols > 2,
          'sm:grid-cols-2': cols === 2 || cols === 4 || cols === 3,
          'sm:grid-cols-3': sm && (cols === 3 || cols === 6),
          'md:grid-cols-2': cols === 4,
          'md:grid-cols-3': cols === 3 || cols === 6,
          'md:grid-cols-5': cols === 5,
          'lg:grid-cols-4': cols === 4,
          'lg:grid-cols-6': cols === 6
        })}
      >
        {items.map((item) => (
          <div
            key={item.label}
            className={classNames(
              'bg-white border dark:bg-black  dark:border-zinc-600 shadow rounded-lg overflow-hidden',
              {
                'px-4 py-5': sm,
                'p-5': !sm && !lg,
                'p-6': lg
              }
            )}
          >
            <dt className='text-sm font-medium truncate text-zinc-500 dark:text-zinc-500 whitespace-nowrap'>
              {item.label}
            </dt>
            <dd
              className={classNames(
                'mt-1 font-semibold text-zinc-900 dark:text-zinc-300 truncate',
                {
                  'text-3xl': lg,
                  'text-2xl': !lg && !sm,
                  'text-xl': sm
                }
              )}
            >
              {item.value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  )
}

import { ArrowSmallDownIcon, ArrowSmallUpIcon } from '@heroicons/react/24/solid'
import type { ReactNode } from 'react'

const stats = [
  {
    name: 'Winning Trades',
    stat: '4',
    previousStat: '70,946',
    percent: '12%',
    changeType: 'increase'
  },
  {
    name: 'Avg. Open Rate',
    stat: '58.16%',
    previousStat: '56.14%',
    change: '2.02%',
    changeType: 'increase'
  },
  {
    name: 'Avg. Click Rate',
    stat: '24.57%',
    previousStat: '28.62%',
    change: '4.05%',
    changeType: 'decrease'
  }
]

export default function Example() {
  return (
    <dl className='grid grid-cols-1 mt-5 overflow-hidden bg-white divide-y rounded-lg shadow divide-zinc-200 md:grid-cols-3 md:divide-y-0 md:divide-x'>
      {stats.map((item) => (
        <div key={item.name} className='px-4 py-5 sm:p-6'>
          <dt className='text-base font-normal text-zinc-900'>{item.name}</dt>
          <dd className='flex items-baseline justify-between mt-1 md:block lg:flex'>
            <div className='flex items-baseline text-2xl font-semibold text-indigo-600'>
              {item.stat}
              <span className='ml-2 text-sm font-medium text-zinc-500'>
                from {item.previousStat}
              </span>
            </div>

            <div
              className={classNames(
                item.changeType === 'increase'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-red-100 text-red-800',
                'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
              )}
            >
              {item.changeType === 'increase' ? (
                <ArrowSmallUpIcon
                  className='-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500'
                  aria-hidden='true'
                />
              ) : (
                <ArrowSmallDownIcon
                  className='-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500'
                  aria-hidden='true'
                />
              )}

              <span className='sr-only'>
                {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by
              </span>
              {item.change}
            </div>
          </dd>
        </div>
      ))}
    </dl>
  )
}

function StatTitle({ children }: { children: ReactNode }) {
  return <dt className='text-base font-normal text-zinc-900'>{children}</dt>
}
