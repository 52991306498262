'use client'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import { Fragment, type ReactNode, useId, useMemo, useState } from 'react'
import type { FieldRenderProps } from 'react-final-form'
import type { SVGElement } from 'ui/types'

interface IconProp {
  outline: SVGElement
  solid: SVGElement
}

export type SelectItemIcon = IconProp | SVGElement

export interface SelectItem {
  id: string | number
  name: string
  icon?: SelectItemIcon
}

export interface OptionContentProps<T> {
  item: T
  selected: T | null
  active: boolean
  showCheckIcon: boolean
}

function OptionContent<T extends SelectItem>({
  item,
  active,
  selected,
  showCheckIcon
}: OptionContentProps<T>) {
  const isSelected = item.id === selected?.id

  return (
    <>
      {item?.icon && (
        <span
          className={classNames(
            'absolute inset-y-0 left-0 flex items-center pl-2 text-gray-700 dark:text-gray-100',
            {
              'text-gray-100': active
            }
          )}
        >
          {typeof item.icon === 'object' ? (
            <item.icon.outline className={classNames('h-4 w-4')} aria-hidden='true' />
          ) : (
            <item.icon
              className={classNames('h-4 w-4', { 'text-gray-100': active })}
              aria-hidden='true'
            />
          )}
        </span>
      )}

      <span
        className={classNames(
          'block truncate',
          selected?.id === item.id ? 'font-bold' : 'font-normal',
          {
            'ml-6': !!item?.icon
          }
        )}
      >
        {item.name}
      </span>

      {showCheckIcon && isSelected ? (
        <span
          className={classNames(
            'absolute inset-y-0 right-0 flex items-center pr-1',
            active ? 'text-white' : 'text-green-600'
          )}
        >
          <CheckIcon className='w-4 h-4' aria-hidden='true' />
        </span>
      ) : null}
    </>
  )
}

export interface SelectProps<T extends SelectItem> {
  label: ReactNode
  items: T[]
  selected: T | null
  setSelected: (item: T) => void
  placeholder?: string
  showCheckIcon?: boolean
  error?: string | undefined
  placementX?: 'left' | 'right'
  disabled?: boolean
}

export function Select<T extends SelectItem>({
  label,
  items,
  placeholder,
  selected,
  setSelected,
  showCheckIcon = true,
  placementX = 'right',
  disabled
}: SelectProps<T>) {
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <Listbox.Label className='block text-xs font-medium text-zinc-500 dark:text-zinc-300'>
            {label}
          </Listbox.Label>
          <div className='relative mt-1'>
            <Listbox.Button className='relative w-full py-1 pl-2 pr-10 text-xs text-left bg-white border rounded shadow-sm cursor-default border-zinc-400 dark:bg-black dark:border-zinc-700 focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-xs'>
              {selected?.icon && (
                <span
                  className={classNames(
                    'text-green-600 absolute inset-y-0 left-0 flex items-center pl-2'
                  )}
                >
                  {typeof selected.icon === 'object' ? (
                    <selected.icon.solid className={classNames('h-5 w-5')} aria-hidden='true' />
                  ) : (
                    <selected.icon className={classNames('h-5 w-5')} aria-hidden='true' />
                  )}
                </span>
              )}
              {selected ? (
                <span
                  className={classNames('block truncate text-gray-900 dark:text-gray-50', {
                    'ml-6': !!selected?.icon
                  })}
                >
                  {selected.name}
                </span>
              ) : (
                <span className='block text-gray-900 truncate opacity-50 dark:text-gray-50'>
                  {placeholder}
                </span>
              )}
              <span className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                <ChevronUpDownIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                className={classNames(
                  'absolute z-10 w-full py-1 mt-1 group overflow-auto text-base bg-zinc-900 rounded-md shadow-lg min-w-max border border-zinc-700 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                  {
                    'right-0': placementX === 'right',
                    'left-0': placementX === 'left'
                  }
                )}
              >
                {items.map((item) => (
                  <Listbox.Option
                    key={item?.id}
                    className={({ active }) =>
                      classNames(
                        'cursor-default text-xs select-none relative py-2 pl-2 mx-1 pr-9 rounded',
                        active ? 'text-white bg-zinc-700' : 'text-gray-700 dark:text-gray-100'
                      )
                    }
                    value={item}
                  >
                    {({ active }) => {
                      return (
                        <OptionContent
                          active={active}
                          item={item}
                          selected={selected}
                          showCheckIcon={showCheckIcon}
                        />
                      )
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export interface NativeSelectProps extends FieldRenderProps<string | number, any> {
  name?: string
  // value?: string | number | readonly string[] | undefined
  id?: string
  type?: string | undefined
  label?: ReactNode
  placeholder?: string
  options?: Record<string, any>[]
  required?: boolean
  caption?: ReactNode
  className?: string | string[]
  error?: string | undefined
  leadingIcon?: SVGElement
  trailingIcon?: SVGElement
  hint?: ReactNode
  labelHidden?: boolean
  touched?: boolean | undefined
  defaultValue?: string | number | readonly string[] | undefined
  children?: ReactNode

  disabled?: boolean
}

export function NativeSelect(props: NativeSelectProps) {
  const id = useId()

  const showErrorState = props.meta.touched && props.meta.error
  return (
    <div>
      <div
        className={classNames('mb-1 flex  items-end', {
          'justify-between': props.label && !props.labelHidden,
          'justify-end': !props.label || props.labelHidden
        })}
      >
        {props.label && (
          <label
            htmlFor={id}
            className={classNames('block text-xs font-medium text-zinc-500 dark:text-zinc-300', {
              'sr-only': props.labelHidden
            })}
          >
            {props.label}
          </label>
        )}
        {showErrorState ? (
          <p className='text-xs text-red-600 dark:text-red-500'>{props.meta?.error}</p>
        ) : props.hint ? (
          <span className='block text-xs text-gray-500 dark:text-gray-400'>{props.hint}</span>
        ) : null}
      </div>
      <div className='relative w-full min-w-0'>
        <select
          id={id}
          name={props?.input?.name}
          onChange={props?.input?.onChange}
          onFocus={props?.input?.onFocus}
          onBlur={props?.input?.onBlur}
          value={props?.input?.value}
          className={classNames(
            // 'focus:ring-green-500 focus:border-green-500 flex-grow block w-full min-w-0 rounded-md sm:text-sm border-gray-300  dark:bg-black dark:border-gray-500 dark:text-gray-300 placeholder-gray-400 dark:placeholder-gray-600 text-gray-700',
            'relative w-full py-1 pl-2 pr-10 text-xs text-left border rounded-md shadow-sm cursor-default border-zinc-400 dark:bg-black text-zinc-300 dark:border-zinc-700 focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-xs',
            {
              'border-red-300 dark:border-red-300 text-red-700  dark:text-red-500 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500':
                !!showErrorState,
              'pl-10': !!props.leadingIcon
            },
            props.className
          )}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
        >
          {props.children
            ? props.children
            : props.options
              ? props.options.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className='text-gray-700 placeholder-gray-400 dark:text-gray-300 dark:placeholder-gray-600'
                  >
                    {option.name}
                  </option>
                ))
              : null}
        </select>
      </div>
      {props.error ? (
        <p className='mt-1.5 text-xs text-red-600 dark:text-red-500'>{props.error}</p>
      ) : props.caption ? (
        <p className='mt-1.5 text-xs text-gray-500 dark:text-gray-400'>{props.caption}</p>
      ) : null}
    </div>
  )
}

export interface UseSelectProps<T extends SelectItem> {
  label: string
  items: T[]
  initialIndex?: number
  showCheckIcon?: boolean
}

export function useSelect<T extends SelectItem>(
  props: UseSelectProps<T>
): { item: T | undefined; Select: JSX.Element } {
  const filters = [...props.items]

  if (props.initialIndex === undefined) {
    filters.unshift({
      id: 0,
      name: `Any ${props.label}`
    } as T)
  }

  const [selected, setSelected] = useState<SelectItem>(filters[props.initialIndex ?? 0])

  const item = useMemo(
    () => props.items.find((_item) => _item.id === selected.id),
    [props.items, selected.id]
  )

  return {
    item,
    Select: (
      <Select
        label={props.label}
        placeholder={`Select ${props.label}`}
        items={filters}
        selected={selected}
        setSelected={setSelected}
        showCheckIcon={props.showCheckIcon}
      />
    )
  }
}
