import { cn } from '@/ui/utils'
import { type ChangeEventHandler, type FocusEvent, type ReactNode, useId } from 'react'

export type CheckboxProps = {
  name?: string
  value?: string | number | readonly string[] | undefined
  id?: string
  type?: string | undefined
  label?: ReactNode
  placeholder?: string
  required?: boolean
  caption?: ReactNode
  className?: string | string[]
  error?: string | undefined
  leadingIcon?: SVGElement
  trailingIcon?: SVGElement
  hint?: ReactNode
  labelHidden?: boolean
  meta?: Record<string, any>
  touched?: boolean | undefined
  input?: Record<string, any>
  checked?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: (event?: FocusEvent<HTMLElement> | undefined) => void
  onFocus?: (event?: FocusEvent<HTMLElement> | undefined) => void
  disabled?: boolean
}

export function Checkbox(props: CheckboxProps) {
  const id = useId()

  return (
    <div className='flex items-start'>
      <div className='flex items-center h-5'>
        <input
          type='checkbox'
          id={id}
          checked={props.checked}
          name={props.name}
          onChange={props?.onChange}
          className={cn('w-4 h-4 rounded form-checkbox border-zinc-300', {
            'text-green-600 cursor-pointer focus:ring-green-500 dark:bg-zinc-900 dark:border-zinc-500 dark:hover:border-zinc-400 checked:bg-green-600 dark:checked:bg-green-600':
              !props.disabled,
            'dark:text-zinc-400 cursor-not-allowed !text-zinc-400 disabled:text-zinc-700 disabled:checked:text-zinc-500 checked:bg-zinc-700 dark:hover:bg-zinc-700':
              props.disabled
          })}
          disabled={props?.disabled}
        />
      </div>
      <div className='h-5 ml-2 text-xs'>
        <label
          htmlFor={id}
          className={cn('flex items-center h-5 font-medium  whitespace-nowrap', {
            'dark:text-zinc-300 cursor-pointer': !props.disabled,
            'dark:text-zinc-500 cursor-not-allowed': props.disabled
          })}
        >
          {props.label}
        </label>
        {props.caption && <p className='text-gray-500 dark:text-gray-400'>{props.caption}</p>}
      </div>
    </div>
  )
}

export type CheckboxFieldProps = CheckboxProps & {
  input: Record<string, any>
  meta: Record<string, any>
}

export function CheckboxField(props: CheckboxFieldProps) {
  return <Checkbox {...props.input} {...props} />
}
