import React, { type ChangeEventHandler } from 'react'

enum FileTypes {
  PNG = 'png',
  JPEG = 'jpg',
  GIF = 'gif'
}

interface FileUploadProps {
  label: JSX.Element | string
  onChange?: ChangeEventHandler<HTMLInputElement>
  allowedTypes?: FileTypes[]
  maxSize?: number // in MB
}

export function FileUpload(props: FileUploadProps) {
  const { allowedTypes = Object.values(FileTypes), maxSize = 10 } = props
  return (
    <div>
      <label className='block text-sm font-medium text-gray-700 dark:text-gray-300'>
        {props.label}
      </label>
      <div className='mt-1 border-2 border-gray-300 dark:border-gray-500 border-dashed rounded-md px-6 pt-5 pb-6 flex justify-center'>
        <div className='space-y-1 text-center'>
          <svg
            className='mx-auto h-12 w-12 text-gray-400 dark:text-gray-300'
            stroke='currentColor'
            fill='none'
            viewBox='0 0 48 48'
            aria-hidden='true'
          >
            <path
              d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
              strokeWidth={2}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          <div className='flex text-sm text-gray-600 dark:text-gray-400'>
            <label
              htmlFor='file-upload'
              className='relative cursor-pointer bg-white dark:bg-black rounded-md font-medium text-green-500 hover:text-green-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-green-500'
            >
              <span>Upload a file</span>
              <input
                id='file-upload'
                name='file-upload'
                type='file'
                className='sr-only'
                onChange={props.onChange}
              />
            </label>
            <p className='pl-1'>or drag and drop</p>
          </div>
          <p className='text-xs text-gray-500 dark:text-gray-400'>
            {allowedTypes.map((type) => type.toUpperCase()).join(', ')} up to {maxSize}MB
          </p>
        </div>
      </div>
    </div>
  )
}
