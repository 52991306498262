'use client'
import { Dialog, Transition } from '@headlessui/react'
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/outline'
import React, { Fragment, type ReactNode, useState } from 'react'
import { ButtonPrimary, ButtonSecondary } from './button'

export function FilterGroup({ title, children }: { title: ReactNode; children: ReactNode }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div className='justify-end hidden space-x-4 md:flex'>{children}</div>
      <div className='flex justify-end md:hidden'>
        <ButtonSecondary xs square icon={FunnelIcon} onClick={() => setOpen(true)} />
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as='div' className='fixed inset-0 z-40 overflow-hidden md:hidden' onClose={setOpen}>
          <div className='absolute inset-0 overflow-hidden'>
            <Dialog.Overlay className='absolute inset-0 transition-opacity bg-opacity-80 bg-zinc-700' />

            <div className='fixed inset-0 flex max-w-full top-1/4'>
              <Transition.Child
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <div className='w-screen'>
                  <div className='flex flex-col h-full bg-white border-t divide-y shadow-xl border-zinc-300 divide-zinc-300 dark:divide-zinc-700 dark:bg-black dark:border-zinc-700'>
                    <div className='flex-shrink-0 p-3'>
                      <div className='flex items-center justify-between '>
                        <Dialog.Title className='text-base font-medium text-zinc-900 dark:text-zinc-300'>
                          {title}
                        </Dialog.Title>
                        <div className='flex items-center ml-3 h-7'>
                          <ButtonSecondary
                            type='button'
                            square
                            xs
                            onClick={() => setOpen(false)}
                            icon={XMarkIcon}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col flex-1 min-h-0 p-3 overflow-y-scroll'>
                      <div className='relative flex-1 space-y-5 '>
                        {React.Children.map(children, (child) => (
                          <div>{child}</div>
                        ))}
                      </div>
                    </div>
                    <div className='flex justify-between flex-shrink-0 p-3'>
                      <ButtonSecondary type='button' onClick={() => setOpen(false)}>
                        Cancel
                      </ButtonSecondary>
                      <ButtonPrimary type='button' onClick={() => setOpen(false)}>
                        Done
                      </ButtonPrimary>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
