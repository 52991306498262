import { type ChangeEventHandler, type FocusEvent, useId } from 'react'

export type RadioProps = {
  id?: string
  label?: string
  checked?: boolean
  value?: string | number | readonly string[] | undefined
  name?: string
  caption?: JSX.Element | JSX.Element[] | string
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: (event?: FocusEvent<HTMLElement> | undefined) => void
  onFocus?: (event?: FocusEvent<HTMLElement> | undefined) => void
}

export function Radio(props: RadioProps) {
  const id = useId()

  return (
    <div className='flex items-start mb-1'>
      <div className='flex items-center h-5'>
        <input
          type='radio'
          id={id}
          value={props.value}
          checked={props.checked}
          name={props.name}
          onChange={props?.onChange}
          className='w-4 h-4 text-green-600 border-gray-300 focus:ring-green-500 dark:bg-gray-900 dark:border-gray-500 dark:hover:border-gray-400 checked:bg-green-600 dark:checked:bg-green-600'
        />
      </div>
      <div className='ml-2 text-xs'>
        <label
          htmlFor={id}
          className='flex items-center h-5 text-xs font-medium text-zinc-700 dark:text-zinc-300'
        >
          {props.label}
        </label>
        {props.caption && <p className='text-zinc-500 dark:text-zinc-400'>{props.caption}</p>}
      </div>
    </div>
  )
}

export type RadioFieldProps = RadioProps & {
  input: Record<string, any>
  meta: Record<string, any>
}

export function RadioField(props: RadioFieldProps) {
  return <Radio {...props.input} {...props} />
}
