import classNames from 'classnames'
import React, { type ReactNode } from 'react'

type ActionPanelProps = {
  title: ReactNode
  description?: ReactNode
  action: ReactNode
  actionPlacement: 'top-right' | 'bottom-right' | 'bottom-left'
}

export function ActionPanel(props: ActionPanelProps) {
  const { actionPlacement = 'bottom-right' } = props
  return (
    <div className='bg-white border rounded-lg shadow dark:bg-black dark:border-zinc-700'>
      <div className='px-4 py-5 sm:p-6'>
        <div
          className={classNames('sm:flex sm:justify-between', {
            'sm:items-start': actionPlacement === 'top-right',
            'sm:items-end': actionPlacement === 'bottom-right',
            'flex-col': actionPlacement === 'bottom-left'
          })}
        >
          <div>
            {typeof props.title === 'string' ? (
              <h3 className='text-lg font-medium leading-6 text-zinc-900 dark:text-zinc-100'>
                {props.title}
              </h3>
            ) : (
              props.title
            )}
            {props.description && (
              <div className='mt-4 text-sm text-zinc-500 dark:text-zinc-400'>
                <p>{props.description}</p>
              </div>
            )}
          </div>
          <div
            className={classNames('mt-5 sm:flex-shrink-0 sm:flex', {
              'sm:mt-5': actionPlacement === 'bottom-left',
              'sm:ml-6 sm:mt-0 sm:items-center': actionPlacement === 'top-right',
              'sm:ml-6 sm:mt-0 sm:items-end': actionPlacement === 'bottom-right'
            })}
          >
            {props.action}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionPanel
