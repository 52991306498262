import { InformationCircleIcon } from '@heroicons/react/24/solid'
import classNames from 'classnames'
import React, { type ReactNode } from 'react'

export interface NoticeProps {
  message: ReactNode
  action?: ReactNode
  green?: boolean
  yellow?: boolean
  red?: boolean
}

export function Notice(props: NoticeProps) {
  return (
    <div
      className={classNames('rounded-md  dark:bg-black dark:border p-3', {
        'bg-green-50 dark:border-green-500': props.green,
        'bg-yellow-50 dark:border-yellow-500': props.yellow,
        'bg-red-50 dark:border-red-500': props.red
      })}
    >
      <div className='flex'>
        <div className='flex-shrink-0'>
          <InformationCircleIcon
            className={classNames('h-5 w-5', {
              'text-green-400 dark:text-green-400': props.green,
              'text-yellow-400 dark:text-yellow-400': props.yellow,
              'text-red-400 dark:text-red-400': props.red
            })}
            aria-hidden='true'
          />
        </div>
        <div className='flex-1 ml-3 md:flex md:justify-between'>
          <p
            className={classNames('text-sm', {
              'text-green-700 dark:text-green-500': props.green,
              'text-yellow-700 dark:text-yellow-500': props.yellow,
              'text-red-700 dark:text-red-500': props.red
            })}
          >
            {props.message}
          </p>
          {props.action && (
            <p
              className={classNames('mt-3 text-sm md:mt-0 md:ml-6 whitespace-nowrap font-medium', {
                'text-green-700 dark:text-green-500 hover:text-green-600': props.green,
                'text-yellow-700 dark:text-yellow-500 hover:text-yellow-600': props.yellow,
                'text-red-700 dark:text-red-500 hover:text-red-600': props.red
              })}
            >
              {props.action}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
